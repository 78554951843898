import React from "react"
import Layout from "../components/Layout"
import TextHighlight from "../components/TextHighlight"
import Button from "../components/Button"
import { Link } from "gatsby"
import Space from "../components/Space"

import styled  from "styled-components"

const Header = styled.section`
  grid-column: 2 / span 6;
  grid-row: 3;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 3;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
  }
`
const ButtonSection = styled.section`
  grid-column: 6 / span 3;
  grid-row: 4;
  justify-self: end;
  @media screen and (max-width: 90rem) {
    grid-column: 3 / span 4;
    grid-row: 4;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 3;
    grid-row: 4;
    justify-self: start;
  }
`

export default function NotFound() {
  return (
    <Layout>
      <Header>
        <div>
          <h1>
            <TextHighlight colorValue={"green"}>404</TextHighlight>
            <br />
            Second time's <br/>
            a charm.
          </h1>
          <h2>
            The page you were looking for<br/>
            <TextHighlight colorValue={"green"}>can't be found</TextHighlight>.
          </h2>
        </div>
      </Header>
      <ButtonSection>
      <Link to="/">
        <Button backHome alignLeft>
          please take me home.
        </Button>
      </Link>
      <Space big four />
      </ButtonSection>
    </Layout>
  )
}
